import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../Components/Navbar";
import Sidebar from "../../Components/Sidebar";
import Loader from "../../Components/Loader";
import SwipeableTemporaryDrawer from "../../Components/Material/MaterialSidebar";
import { Link, useNavigate, useParams } from "react-router-dom";
import BasicButton from "../../Components/Material/Button";
import instance from "../../Instance";
import Cookies from "js-cookie";
import BasicTextFields from "../../Components/Material/TextField";
import { ShowError, ShowSuccess } from "../../util/showError";

const ApproveUpdateSchoolReq = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [schoolData, setSchoolData] = useState(null);
  const sidebarRef = useRef();
  const navigate = useNavigate();
  const show = null;

  const { id } = useParams();

  const navInfo = {
    title: "Approve Varthana",
    details: ["Home", "Approve Varthana Form"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };
  const getSchoolList = async () => {
    try {
      const res = await instance({
        method: "GET",
        url: "varthana/" + id,
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      });
      setSchoolData(res.data);
    } catch (error) {}
  };

  // console.log("++++", schoolData);

  useLayoutEffect(() => {
    getSchoolList();
  }, []);

  const handleSubmit = async (status) => {
    try {
      let apiUrl;
      let method;

      if (status === "approved") {
        apiUrl = `varthana/approve/${id}`;
        method = "PATCH";
      } else if (status === "rejected") {
        apiUrl = `varthana/reject/${id}`;
        method = "PATCH";
      } else if (status === "export") {
        apiUrl = `varthana/exportToVarthana/${id}`;
        method = "PUT";
      }

      const res = await instance({
        method: method,
        url: apiUrl,
        headers: {
          Authorization: `${Cookies.get("accessToken")}`,
        },
      });

      if (res.data.status === "success") {
        ShowSuccess(res.data.message);
        setTimeout(() => {
          navigate("/admin/managevarthana/varthana-finance");
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();

    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        {loading ? <Loader /> : null}

        <Sidebar
          highLight={"manage_school"}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={"manage_school"}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
          />

          <div className="min-h-[90vh] flex w-full flex-col justify-center items-center gap-4 bg-[#141728] px-4">
            <section className="flex justify-center w-full mt-4 sm:flex-row flex-col flex-wrap mx-8 items-start gap-12">
              {schoolData ? (
                <>
                  <div className=" flex flex-col gap-3 items-center justify-center p-4 bg-slate-500 text-gray-100 font-semibold rounded-md">
                    <p>School Name : {schoolData?.data?.schoolDetails?.name}</p>
                    <p>Employee Code : {schoolData?.data?.employeeCode}</p>

                    <p>Lead Source : {schoolData?.data?.leadSource}</p>

                    <p>
                      Loan Amount Required :{" "}
                      {schoolData?.data?.loanAmountRequired}
                    </p>
                    <p>
                      Annual Fee Collection :{" "}
                      {schoolData?.data?.annualFeeCollection}
                    </p>

                    <p>
                      Affiliation From : {schoolData?.data?.affiliationFrom}
                    </p>

                    <p>
                      Name :{schoolData?.data?.applicantDetails?.first_name}{" "}
                      {schoolData?.data?.applicantDetails?.last_name}
                    </p>

                    <p>Phone : {schoolData?.data?.applicantDetails?.phone}</p>

                    <p>Total Students : {schoolData?.data?.totalStudents}</p>

                    <p>Society Name : {schoolData?.data?.societyName}</p>

                    <p>
                      School Start Year : {schoolData?.data?.schoolStartYear}
                    </p>

                    <p>Pincode : {schoolData?.data?.schoolDetails?.pincode}</p>

                    <p>State : {schoolData?.data?.schoolDetails?.State}</p>

                    <p>Address: {schoolData?.data?.schoolDetails?.address}</p>
                    <p>Lead Type: {schoolData?.data?.leadType}</p>

                    <p>City : {schoolData?.data?.schoolDetails?.city}</p>
                    <p>Purpose :{schoolData?.data?.purpose}</p>
                  </div>
                </>
              ) : null}
            </section>

            <div className="w-full flex justify-start gap-4 flex-col bg-slate-500 p-8 rounded-md mb-10">
              <div className="w-full flex justify-start gap-4">
                {schoolData?.data?.approvalStatus === "pending" ||
                schoolData?.data?.approvalStatus === "rejected" ? (
                  <div className="w-full flex justify-start gap-4">
                    <div onClick={() => handleSubmit("approved")}>
                      <BasicButton text={"Approve"} />
                    </div>
                    <div onClick={() => handleSubmit("rejected")}>
                      <BasicButton text={"Reject"} />
                    </div>
                  </div>
                ) : null}

                {schoolData?.data?.approvalStatus === "approved" ? (
                  <div onClick={() => handleSubmit("export")}>
                    <BasicButton text={"Export"} />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveUpdateSchoolReq;
