import {
  Circle,
  Dashboard,
  KeyboardArrowDown,
  Person,
  PrintOutlined,
  School,
  Scoreboard,
} from "@mui/icons-material";
import { Collapse } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";

const AdminSidebar = ({ highLight }) => {
  const [schoolOpen, setSchoolOpen] = useState(false);
  return (
    <>
      <Link to="/admin/home">
        <aside
          className={`px-6 py-2 hover:bg-gray-500 flex ${
            highLight === "dashboard" ? "bg-gray-500" : ""
          } rounded-md gap-4 cursor-pointer group`}
        >
          <div className="flex gap-4">
            <Dashboard
              className={`${
                highLight === "dashboard" ? "!text-[#659DBD]" : "!text-gray-400"
              } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
            />
            <span
              className={`${
                highLight === "dashboard" ? "text-gray-200" : "text-gray-400"
              } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
            >
              DashBoard
            </span>
          </div>
          {/* <hr className="text-gray-300" /> */}
        </aside>
      </Link>
      <Link to="/print_pdf">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "printpdf" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <PrintOutlined
            className={`${
              highLight === "printpdf" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "printpdf" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Doc Print
          </span>
        </aside>
      </Link>
      <Link to="/admin/usersMangement">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "user_management" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <Person
            className={`${
              highLight === "user_management"
                ? "!text-[#659DBD]"
                : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "user_management"
                ? "text-gray-200"
                : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            User Management
          </span>
        </aside>
      </Link>
      <Link>
        <aside
          onClick={() => setSchoolOpen(!schoolOpen)}
          className={`px-6 py-2 flex justify-between gap-4 ${
            highLight === "manage_school" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <div className="flex gap-4">
            <School
              className={`${
                highLight === "manage_school"
                  ? "!text-gray-100"
                  : "!text-gray-400"
              } group-hover:!text-gray-100 !transition-all !duration-150 !ease-linear`}
            />
            <span
              className={`${
                highLight === "manage_school"
                  ? "text-gray-200"
                  : "text-gray-400"
              } group-hover:!text-gray-100 font-semibold transition-all duration-150 ease-linear`}
            >
              Manage School
            </span>
          </div>
          <div
            className={`transition-all duration-200  ease-linear ${
              schoolOpen ? null : "-rotate-90"
            }`}
          >
            <KeyboardArrowDown className={`text-gray-600 `} />
          </div>
        </aside>
        <Collapse in={schoolOpen}>
          {" "}
          <div
            className={`${
              schoolOpen ? "h-fit opacity-100 visible" : null
            } transition-all ease-linear duration-200`}
          >
            <Link to="/admin/manageschool/updateSchool">
              <div
                className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
              >
                <Circle
                  className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                />
                <h1
                  className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                >
                  School Update Request
                </h1>
              </div>
            </Link>
            <Link to="/admin/managevarthana/varthana-finance">
              <div
                className={`flex items-center transition-all ease-linear duration-100 mr-8 ml-6 group `}
              >
                <Circle
                  className={`!text-[.7rem] !transition-all !ease-linear !duration-200 text-gray-300`}
                />
                <h1
                  className={`pl-9 text-gray-300 transition-all ease-linear text-sm font-semibold duration-200  py-2 cursor-pointer`}
                >
                  Varthana Finance
                </h1>
              </div>
            </Link>
          </div>
        </Collapse>
      </Link>
      <Link to="/admin/employeeReimbursementStatement">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "employee_reimbursement_statement"
              ? "bg-gray-500"
              : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <Person
            className={`${
              highLight === "employee_reimbursement_statement"
                ? "!text-[#659DBD]"
                : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "employee_reimbursement_statement"
                ? "text-gray-200"
                : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Employee Reimbursement Statement
          </span>
        </aside>
      </Link>

      {/* <Link to="/admin/manageschool">
        <aside
          className={`px-6 py-2 flex gap-4 cursor-pointer ${
            highLight === "manageSchool" ? "bg-gray-500" : ""
          } group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <School
            className={`${
              highLight === "manageSchool"
                ? "!text-[#659DBD]"
                : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "manageSchool" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Manage School
          </span>
        </aside>
      </Link>

      <Link to="/admin/uploadinvoice">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "uploadinvoice" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <DocumentScanner
            className={`${
              highLight === "uploadinvoice"
                ? "!text-[#659DBD]"
                : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "uploadinvoice" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Upload Invoice
          </span>
        </aside>
      </Link>

      <Link to="/admin/invoice">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "invoice" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <ReceiptOutlined
            className={`${
              highLight === "invoice" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "invoice" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            Invoice Tagging
          </span>
        </aside>
      </Link>

      <Link to="/admin/ckreport">
        <aside
          className={`px-6 py-2 flex gap-4 ${
            highLight === "ckreport" ? "bg-gray-500" : ""
          } cursor-pointer group hover:bg-gray-500 rounded-md transition-all duration-150 ease-linear`}
        >
          <Article
            className={`${
              highLight === "ckreport" ? "!text-[#659DBD]" : "!text-gray-400"
            } group-hover:!text-[#659DBD] !transition-all !duration-150 !ease-linear`}
          />
          <span
            className={`${
              highLight === "ckreport" ? "text-gray-200" : "text-gray-400"
            } group-hover:!text-gray-100 transition-all duration-150 ease-linear`}
          >
            CK Report
          </span>
        </aside>
      </Link> */}
    </>
  );
};

export default AdminSidebar;
