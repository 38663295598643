import React, { useEffect, useLayoutEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../Components/Navbar";
import Sidebar from "../Components/Sidebar";
import { useFormik } from "formik";
import { useParams, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import SwipeableTemporaryDrawer from "../Components/Material/MaterialSidebar";
import BasicButton from "../Components/Material/Button";
import CustomizedSteppers from "../Components/Material/Stepper";

import {
  Autocomplete,
  Backdrop,
  CircularProgress,
  TextField,
} from "@mui/material";
import instance from "../Instance";
import Snackbars from "../Components/Material/SnackBar";

const VarthanaForm = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [boards, setBoards] = useState([]);
  const [category, setCategory] = useState([]);
  const [errMessage, setErrMessage] = useState("");
  const [snackbarErrStatus, setSnackbarErrStatus] = useState(true);
  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);
  const [schoolData, setSchoolData] = useState({
    school_name: "",
    trust: "",
    loanamount: "",
    totalstudents: "",
    purpose_loan: "",
    lead_source: "",
    // lead_type: "",
    remarks_data: "",
    annual_feeCollection: "",
    school_startYear: "",
    afflication_code: "",
    affiliation_from: [
      "CBSE",
      "ICSE",
      "IB",
      "IGCSE",
      "State Board",
      "Nursing",
      "Pharmacy",
      "Engineering",
      "Degree College",
      "Others",
    ],
    affiliation_othersDetail: "",
    fk_board: { id: "", board_name: "" },
    fk_category: { id: "", schoolCategory: "" },
    school_contacts: [
      { designation: "", email: "", id: "", name: "", phone: "", website: "" },
    ],
    school_addresses: [
      {
        address: "",
        fk_city: { city: "", id: "" },
        fk_state: { id: "", state: "" },
        pin: "",
        id: "",
      },
    ],
  });

  const { id } = useParams();
  const sidebarRef = useRef();
  const snackbarRef = useRef();
  const show = null;
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      school_name: schoolData.school_name,
      pin_code: schoolData.school_addresses[0].pin,
      state: schoolData.school_addresses[0].fk_state.id,
      city: schoolData.school_addresses[0].fk_city.id,
      address: schoolData.school_addresses[0].address,
      aff_code: schoolData.afflication_code,
      board: schoolData.fk_board.id,
      category: schoolData.fk_category.id,
      trust: schoolData.trust,
      loanamount: schoolData.loanamount,
      totalstudents: schoolData.totalstudents,
      purpose_loan: schoolData.purpose_loan,
      lead_source: schoolData.lead_source,
      remarks_data: schoolData.remarks_data,
      school_startYear: schoolData.school_startYear,
      annual_feeCollection: schoolData.annual_feeCollection,

      // name: schoolData.school_contacts[0].name,
      // email: schoolData.school_contacts[0].email,
      // phone: schoolData.school_contacts[0].phone,
      // web: schoolData.school_contacts[0].website,
      // designation: schoolData.school_contacts[0].designation,
    },

    // onSubmit: async (values) => {
    //   setLoading(true);

    //   const res = await instance({
    //     url: `school/update/${id}`,
    //     method: "PUT",
    //     data: {
    //       school_name: formik.values.school_name,
    //       pin: formik.values.pin_code,
    //       state: formik.values.state,
    //       city: formik.values.city,
    //       address: formik.values.address,
    //       aff_code: formik.values.aff_code,
    //       board: formik.values.board,
    //       category: formik.values.category,
    //       // status: false,
    //       // pName: formik.values.name,
    //       // pEmail: formik.values.email,
    //       // pPhone: formik.values.phone,
    //       // web: formik.values.web,
    //       // designation: formik.values.designation,
    //     },
    //     headers: {
    //       Authorization: Cookies.get("accessToken"),
    //     },
    //   }).catch((err) => {
    //     setLoading(false);
    //   });
    //   if (res.data.status === "success") {
    //     setSnackbarErrStatus(false);
    //     setErrMessage(res.data.message);
    //     snackbarRef.current.openSnackbar();

    //     setTimeout(() => {
    //       navigate(`/manageSchool`);
    //     }, 1500);
    //   }

    //   setLoading(false);
    // },

    onSubmit: async (values) => {
      setLoading(true);

      const res = await instance({
        url: `varthana/create`,
        method: "POST",
        data: {
          schoolId: id,
          trustName: formik.values.trust,
          loanAmount: formik.values.loanamount,
          totalStudents: formik.values.totalstudents,
          purpose: formik.values.purpose_loan,
          leadSource: formik.values.lead_source,
          leadType: "Institute",
          remarks: formik.values.remarks_data,
          affiliationFrom: "CBSE",
          // affiliationOthersDetail:
          //   "if affiliationFrom is selected as other, fill this",
          annualFeeCollection: formik.values.annual_feeCollection,
          schoolStartYear: formik.values.school_startYear,
        },
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      }).catch((err) => {
        setLoading(false);
      });
      if (res.data.status === "success") {
        setSnackbarErrStatus(false);
        setErrMessage(res.data.message);
        snackbarRef.current.openSnackbar();

        setTimeout(() => {
          navigate(`/varthana_school`);
        }, 1500);
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    formik.values.school_name = schoolData.school_name;
    formik.values.pin_code = schoolData.school_addresses[0].pin;
    formik.values.state = schoolData.school_addresses[0].fk_state.id;
    formik.values.city = schoolData.school_addresses[0].fk_city.id;
    formik.values.address = schoolData.school_addresses[0].address;
    formik.values.aff_code = schoolData.afflication_code;
    formik.values.board = schoolData.fk_board.id;
    formik.values.category = schoolData.fk_category.id;
    formik.values.trust = schoolData.trust;
    formik.values.loanamount = schoolData.loanamount;
    formik.values.totalstudents = schoolData.totalstudents;
    formik.values.purpose_loan = schoolData.purpose_loan;
    formik.values.lead_source = schoolData.lead_source;
    formik.values.remarks_data = schoolData.remarks_data;
    formik.values.school_startYear = schoolData.school_startYear;
    formik.values.annual_feeCollection = schoolData.annual_feeCollection;
    // formik.values.name = schoolData.school_contacts[0].name;
    // formik.values.email = schoolData.school_contacts[0].email;
    // formik.values.phone = schoolData.school_contacts[0].phone;
    // formik.values.web = schoolData.school_contacts[0].website;
    // formik.values.designation = schoolData.school_contacts[0].designation;
  }, [schoolData]);

  const handleOrderProcessingForm = async (value, type) => {
    switch (type) {
      case "Enter School Name *":
        setSchoolData({ ...schoolData, school_name: value });
        formik.values.school_name = value;
        break;
      case "Enter Pincode *":
        setSchoolData({
          ...schoolData,
          school_addresses: [
            {
              id: schoolData.school_addresses[0].id,
              address: schoolData.school_addresses[0].address,
              pin: value,
              fk_city: schoolData.school_addresses[0].fk_city,
              fk_state: schoolData.school_addresses[0].fk_state,
            },
          ],
        });
        formik.values.pin_code = value;
        break;

      case "select_state":
        const s = state.find((item) => item.fk_state.state === value);

        formik.values.state = s.fk_state.id;
        setSchoolData({
          ...schoolData,
          school_addresses: [
            {
              address: schoolData.school_addresses[0].address,
              fk_state: s.fk_state,
              fk_city: schoolData.school_addresses[0].fk_city,
              id: schoolData.school_addresses[0].id,
              pin: schoolData.school_addresses[0].pin,
            },
          ],
        });
        break;
      case "select_city":
        const c = city.find((item) => item.city === value);
        formik.values.city = c.id;
        setSchoolData({
          ...schoolData,
          school_addresses: [
            {
              address: schoolData.school_addresses[0].address,
              fk_state: schoolData.school_addresses[0].fk_state,
              fk_city: c,
              id: schoolData.school_addresses[0].id,
              pin: schoolData.school_addresses[0].pin,
            },
          ],
        });
        break;
      case "Enter Address *":
        setSchoolData({
          ...schoolData,
          school_addresses: [
            {
              id: schoolData.school_addresses[0].id,
              address: value,
              pin: schoolData.school_addresses[0].pin,
              fk_city: schoolData.school_addresses[0].fk_city,
              fk_state: schoolData.school_addresses[0].fk_state,
            },
          ],
        });
        formik.values.address = value;
        break;

      case "Trust/Society Name *":
        setSchoolData({ ...schoolData, trust: value });
        formik.values.trust = value;
        break;
      case "Trust/Society Name *":
        setSchoolData({ ...schoolData, trust: value });
        formik.values.trust = value;
        break;

      case "board_name":
        const board = boards.find((item) => item.board_name === value);
        formik.values.board = board.id;
        setSchoolData({
          ...schoolData,
          fk_board: { id: board.id, board_name: board.board_name },
        });
        break;
      case "category":
        const categoryNew = category.find(
          (item) => item.schoolCategory === value
        );

        formik.values.category = categoryNew.id;
        setSchoolData({
          ...schoolData,
          fk_category: {
            id: categoryNew.id,
            schoolCategory: categoryNew.schoolCategory,
          },
        });
        break;

      case "Loan amount required *":
        setSchoolData({ ...schoolData, loanamount: value });
        formik.values.loanamount = value;
        break;

      case "Total number of students *":
        setSchoolData({ ...schoolData, totalstudents: value });
        formik.values.totalstudents = value;
        break;
      case "Purpose of Loan *":
        setSchoolData({ ...schoolData, purpose_loan: value });
        formik.values.purpose_loan = value;
        break;

      case "Lead Source *":
        setSchoolData({ ...schoolData, lead_source: value });
        formik.values.lead_source = value;
        break;

      case "Remarks *":
        setSchoolData({ ...schoolData, remarks_data: value });
        formik.values.remarks_data = value;
        break;

      case "School Started Year *":
        setSchoolData({ ...schoolData, school_startYear: value });
        formik.values.school_startYear = value;
        break;

      case "Total Annual School Fee Collection *":
        setSchoolData({ ...schoolData, annual_feeCollection: value });
        formik.values.annual_feeCollection = value;
        break;

      case "Enter Affiliate Code *":
        setSchoolData({ ...schoolData, afflication_code: value });
        formik.values.aff_code = value;
        break;

      case "Enter Name *":
        setSchoolData({
          ...schoolData,
          school_contacts: [
            {
              name: value,
              designation: schoolData.school_contacts[0].designation,
              email: schoolData.school_contacts[0].email,
              phone: schoolData.school_contacts[0].phone,
              website: schoolData.school_contacts[0].website,
              id: schoolData.school_contacts[0].id,
            },
          ],
        });
        formik.values.name = value;
        break;
      case "Enter Email *":
        setSchoolData({
          ...schoolData,
          school_contacts: [
            {
              name: schoolData.school_contacts[0].name,
              designation: schoolData.school_contacts[0].designation,
              email: value,
              phone: schoolData.school_contacts[0].phone,
              website: schoolData.school_contacts[0].website,
              id: schoolData.school_contacts[0].id,
            },
          ],
        });
        formik.values.email = value;
        break;
      case "Enter Phone *":
        setSchoolData({
          ...schoolData,
          school_contacts: [
            {
              name: schoolData.school_contacts[0].name,
              designation: schoolData.school_contacts[0].designation,
              email: schoolData.school_contacts[0].email,
              phone: value,
              website: schoolData.school_contacts[0].website,
              id: schoolData.school_contacts[0].id,
            },
          ],
        });
        formik.values.phone = value;
        break;
      case "Enter Website *":
        setSchoolData({
          ...schoolData,
          school_contacts: [
            {
              name: schoolData.school_contacts[0].name,
              designation: schoolData.school_contacts[0].designation,
              email: schoolData.school_contacts[0].email,
              phone: schoolData.school_contacts[0].phone,
              website: value,
              id: schoolData.school_contacts[0].id,
            },
          ],
        });
        formik.values.web = value;
        break;
      case "Enter Designation *":
        setSchoolData({
          ...schoolData,
          school_contacts: [
            {
              name: schoolData.school_contacts[0].name,
              designation: value,
              email: schoolData.school_contacts[0].email,
              phone: schoolData.school_contacts[0].phone,
              website: schoolData.school_contacts[0].website,
              id: schoolData.school_contacts[0].id,
            },
          ],
        });
        formik.values.designation = value;
        break;

      default:
        break;
    }
    // console.log(formik.values);
  };

  useLayoutEffect(() => {
    const getBoards = async () => {
      const boards = await instance({
        url: "school/board/get/findAll",
        method: "GET",
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      setBoards(boards.data.message);
    };
    const getCategory = async () => {
      const category = await instance({
        url: "school/category/get/findAll",
        method: "GET",
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      setCategory(category.data.message);
    };
    const getState = async () => {
      const state = await instance({
        url: "location/state/get/states",
        method: "GET",
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });

      setState(state.data.message);
      const city = await instance({
        url: `location/city/${schoolData.school_addresses[0].fk_state.id}`,
        method: "GET",
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });
      setCity(city.data.message);
    };
    const getSchoolData = async () => {
      const schoolDetails = await instance({
        url: `school/schoolcheck/school/${id}`,
        method: "GET",
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });

      setSchoolData({
        ...schoolData,
        school_name: schoolDetails.data.message[0].school_name,
        afflication_code: schoolDetails.data.message[0].afflication_code,
        fk_board: {
          board_name: schoolDetails.data.message[0].fk_board.board_name,
          id: schoolDetails.data.message[0].fk_board.id,
        },
        fk_category: {
          schoolCategory:
            schoolDetails.data.message[0].fk_category.schoolCategory,
          id: schoolDetails.data.message[0].fk_category.id,
        },
        school_contacts: [
          {
            name:
              schoolDetails.data.message[0].school_contacts.length > 0
                ? schoolDetails.data.message[0].school_contacts[0].name
                : "",
            email:
              schoolDetails.data.message[0].school_contacts.length > 0
                ? schoolDetails.data.message[0].school_contacts[0].email
                : "",
            phone:
              schoolDetails.data.message[0].school_contacts.length > 0
                ? schoolDetails.data.message[0].school_contacts[0].phone
                : "",
            website:
              schoolDetails.data.message[0].school_contacts.length > 0
                ? schoolDetails.data.message[0].school_contacts[0].website
                : "",
            designation:
              schoolDetails.data.message[0].school_contacts.length > 0
                ? schoolDetails.data.message[0].school_contacts[0].designation
                : "",
            id:
              schoolDetails.data.message[0].school_contacts.length > 0
                ? schoolDetails.data.message[0].school_contacts[0].id
                : "",
          },
        ],
        school_addresses: [
          {
            address:
              schoolDetails.data.message[0].school_addresses.length > 0
                ? schoolDetails.data.message[0].school_addresses[0].address
                : "",
            pin: schoolDetails.data.message[0].school_addresses[0].pin,
            fk_city: {
              city:
                schoolDetails.data.message[0].school_addresses.length > 0
                  ? schoolDetails.data.message[0].school_addresses[0].fk_city
                      .city
                  : "",
              id:
                schoolDetails.data.message[0].school_addresses.length > 0
                  ? schoolDetails.data.message[0].school_addresses[0].fk_city.id
                  : "",
            },
            fk_state: {
              id:
                schoolDetails.data.message[0].school_addresses.length > 0
                  ? schoolDetails.data.message[0].school_addresses[0].fk_state
                      .id
                  : "",
              state:
                schoolDetails.data.message[0].school_addresses.length > 0
                  ? schoolDetails.data.message[0].school_addresses[0].fk_state
                      .state
                  : "",
            },
          },
        ],
      });
    };
    getBoards();
    getCategory();
    getState();
    getSchoolData();
  }, []);

  const navInfo = {
    title: "Fill Form",
    details: ["Home", "/Fill Form"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };
    window.addEventListener("resize", handleWidth);
    handleWidth();
    window.scroll(0, 0);

    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);
  // console.log("SchoolData-------", schoolData.trust);

  return (
    <>
      <div className="flex w-[100%] min-h-[100vh]">
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={loading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <Sidebar
          highLight={""}
          sidebarCollapsed={sidebarCollapsed}
          show={show}
        />

        <div>
          <SwipeableTemporaryDrawer
            ref={sidebarRef}
            sidebarCollapsed={sidebarCollapsed}
            show={show}
            highLight={""}
          />
        </div>
        <div
          className={`flex flex-col w-[100vw] relative transition-all ease-linear duration-300 lg:w-[83vw] lg:ml-[18vw] ${
            window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[85vw]"
          } `}
        >
          <Snackbars
            ref={snackbarRef}
            snackbarErrStatus={snackbarErrStatus}
            errMessage={errMessage}
          />
          <Navbar
            handleSidebarCollapsed={handleSidebarCollapsed}
            info={navInfo}
          />

          <div className="min-h-[90vh] relative flex w-full justify-center items-start gap-4 bg-[#141728]">
            <h1 className="text-gray-100 md:text-2xl text-base font-semibold absolute top-[2rem] left-[2rem]">
              Form School
            </h1>
            <div className="w-full flex flex-col gap-4 items-center mt-[7rem]">
              <div className="flex flex-col gap-4 items-start w-[90%] px-6 bg-slate-600 rounded-md py-6 mb-[5rem]">
                <div className="grid sm:grid-rows-2 sm:grid-cols-2 grid-rows-4 grid-cols-1 w-full mt-6 gap-6 rounded-md bg-slate-600">
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Enter School Name *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.school_name}
                    label="Enter School Name *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Enter Pincode *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.school_addresses[0].pin}
                    label="Enter Pincode *"
                    variant="standard"
                  />
                  {state ? (
                    <Autocomplete
                      value={schoolData?.school_addresses[0]?.fk_state.state}
                      options={state?.map((item) => {
                        return item.fk_state.state;
                      })}
                      onChange={(event, newValue) => {
                        handleOrderProcessingForm(newValue, "select_state");
                      }}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select State *"
                          variant="standard"
                          InputLabelProps={{
                            style: { color: "rgb(243, 244, 246)" },
                          }}
                        />
                      )}
                    />
                  ) : null}
                  {city ? (
                    <Autocomplete
                      value={schoolData?.school_addresses[0]?.fk_city.city}
                      options={city?.map((item) => {
                        return item.city;
                      })}
                      onChange={(event, newValue) => {
                        handleOrderProcessingForm(newValue, "select_city");
                      }}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select City *"
                          variant="standard"
                          InputLabelProps={{
                            style: { color: "rgb(243, 244, 246)" },
                          }}
                        />
                      )}
                    />
                  ) : null}
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Enter Address *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.school_addresses[0].address}
                    label="Enter Address *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(e.target.value, "Enter Name *")
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.school_contacts[0].name}
                    label="Enter Name *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    type={"number"}
                    onChange={(e) =>
                      handleOrderProcessingForm(e.target.value, "Enter Phone *")
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.school_contacts[0].phone}
                    label="Enter Phone *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Trust/Society Name *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.trust}
                    label="Trust/Society Name *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Loan amount required *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.loanamount}
                    label="Loan amount required *"
                    type="number"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Purpose of Loan *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.purpose_loan}
                    label="Purpose of Loan *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Enter Website *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value="Institute"
                    label="Lead Type *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Total number of students *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.totalstudents}
                    label="Total number of students *"
                    type="number"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(e.target.value, "Lead Source *")
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.lead_source}
                    label="Lead Source *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(e.target.value, "Remarks *")
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.remarks_data}
                    label="Remarks *"
                    variant="standard"
                  />
                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "Total Annual School Fee Collection *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.annual_feeCollection}
                    label="Total Annual School Fee Collection *"
                    type="number"
                    variant="standard"
                  />

                  <TextField
                    id="standard-basic"
                    onChange={(e) =>
                      handleOrderProcessingForm(
                        e.target.value,
                        "School Started Year *"
                      )
                    }
                    inputProps={{ style: { color: "white" } }}
                    InputLabelProps={{ style: { color: "white" } }}
                    value={schoolData.school_startYear}
                    label="School Started Year *"
                    type="number"
                    variant="standard"
                  />

                  {state ? (
                    <Autocomplete
                      value={schoolData?.school_addresses[0]?.fk_state.state}
                      options={state?.map((item) => {
                        return item.fk_state.state;
                      })}
                      onChange={(event, newValue) => {
                        handleOrderProcessingForm(newValue, "select_state");
                      }}
                      sx={{ width: 200 }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Select State *"
                          variant="standard"
                          InputLabelProps={{
                            style: { color: "rgb(243, 244, 246)" },
                          }}
                        />
                      )}
                    />
                  ) : null}
                </div>

                <div className="mt-3" onClick={formik.handleSubmit}>
                  <BasicButton text={"Submit Form"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VarthanaForm;
