import React, { useEffect, useRef } from "react";
import { useState } from "react";
import Navbar from "../../../Components/Navbar";
import Sidebar from "../../../Components/Sidebar";
import SwipeableTemporaryDrawer from "../../../Components/Material/MaterialSidebar";
import { useLayoutEffect } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import ReactGA from "react-ga4";
import axios from "axios";
import CollapsibleTable from "../../../Components/TrainerInterventions/CollapsableTable";
import instance from "../../../Instance";
import Cookies from "js-cookie";
import ckInstance from "../../../ckInstance";

const SalesInterventionDashboard = () => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [highLight, setHighLight] = useState("intervention");
  const [loading, setLoading] = useState(false);
  const [schools, setSchool] = useState([]);
  const sidebarRef = useRef();

  const navInfo = {
    title: "Dashboard",
    details: ["Home", " / Dashboard"],
  };

  const handleSidebarCollapsed = () => {
    sidebarRef.current.openSidebar();
  };

  useEffect(() => {
    const handleWidth = () => {
      if (window.innerWidth > 1024) {
        setSidebarCollapsed(false);
      } else {
        setSidebarCollapsed(true);
      }
    };

    window.addEventListener("resize", handleWidth);
    handleWidth();
    const pagePath = window.location.pathname;

    ReactGA.send({
      hitType: "pageview",
      page: pagePath,
      title: "User Page Count",
    });
    window.scroll(0, 0);
    return () => {
      window.removeEventListener("resize", handleWidth);
    };
  }, []);

  // const returnCkAuthToken = async () => {
  //   const params = new URLSearchParams();
  //   params.append("grant_type", "password");
  //   params.append("client_id", "c2Nob29sX21pdHJh");
  //   params.append("secret", "Y2xhc3NrbGFwQDIwMjM=");
  //   params.append("email", "prateek.mudliar@classklap.com");
  //   const res = await axios.post(
  //     "https://sb3.imaxprogram.com/app/v1/login/eupheus",
  //     params,
  //     {
  //       headers: {
  //         "Content-Type": "application/x-www-form-urlencoded",
  //       },
  //     }
  //   );
  //   return res.data.accessToken;
  // };

  useLayoutEffect(() => {
    // const getCkSchools = async () => {
    //   const token = await returnCkAuthToken();
    //   const getSchool = await axios.get(
    //     "https://sb3.imaxprogram.com/app/visor/allSchools",
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   const schoolListData = getSchool.data.map((school) => {
    //     return { id: school.code, label: school.name };
    //   });
    //   setSchool(schoolListData);
    // };

    const getUsersCkSchool = async () => {
      const res = await instance({
        url: "school/ck/school/byUser",
        method: "GET",
        headers: {
          Authorization: Cookies.get("accessToken"),
        },
      });

      const schoolListData = res.data.map((school) => {
        return {
          id: school.ck_code,
          label: school.school_name,
          trainer: "Trainer Name",
        };
      });

      // ck login
      const returnCkAuthToken = async () => {
        const params = new URLSearchParams();
        params.append("grant_type", "password");
        params.append("client_id", "c2Nob29sX21pdHJh");
        params.append("secret", "Y2xhc3NrbGFwQDIwMjM=");
        params.append("email", "prateek.mudliar@classklap.com");

        const res = await ckInstance({
          url: `v1/login/eupheus`,
          method: "POST",
          data: params,
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        });
        return res.data.accessToken;
      };

      const token = await returnCkAuthToken();

      for (let i = 0; i < schoolListData.length; i++) {
        const school = schoolListData[i];
        const data = await ckInstance({
          method: "GET",
          url: `visor/school/${school.id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).catch(() => {
          console.log("couldn't find school");
          schoolListData[i].trainer = "NA";
        });
        if (data?.status === 200) {
          schoolListData[i].trainer = data.data.coordinator
            .join(", ")
            .replaceAll("null", "");
        }
      }
      setSchool(schoolListData);
    };

    // getCkSchools();
    getUsersCkSchool();
  }, []);

  const columns = ["School Name", "School Code" ,"Trainer"];

  return (
    <div className="flex bg-[#111322]">
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Sidebar sidebarCollapsed={sidebarCollapsed} highLight={highLight} />

      <div>
        <SwipeableTemporaryDrawer
          ref={sidebarRef}
          sidebarCollapsed={sidebarCollapsed}
          highLight={highLight}
          // show={show}
        />
      </div>

      <div
        className={`flex flex-col w-[100vw] lg:w-[83vw] lg:ml-[18vw] ${
          window.innerWidth < 1024 ? null : "md:ml-[30vw] ml-[60vw]"
        } `}
      >
        <Navbar
          handleSidebarCollapsed={handleSidebarCollapsed}
          info={navInfo}
        />
        <div className="min-h-[100vh] pt-[2vh] max-h-full bg-[#141728]">
          <div className=" sm:px-8 px-2 py-3 bg-[#141728]">
            <CollapsibleTable
              columns={columns}
              data={schools}
              setLoading={setLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SalesInterventionDashboard;
